import React from "react";
import { Router as ReachRouter } from "@reach/router";

import Dashboard from "./containers/Dashboard";
import Privacy from "./containers/Terms/Privacy";
import NotFoundPage from "./components/NotFoundPage";
import Layout from "./containers/Layout";
import Service from "./containers/Terms/Service";
import Return from "./containers/Terms/Return";

const ScrollToTop = ({ children, location }) => {
  React.useEffect(() => {
    document.body.style.overflow = "visible";
    return window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
export default function Router() {
  return (
    <ReachRouter primary={false}>
      <ScrollToTop path="/">
        <Layout path="/">
          <Dashboard path="/" />
          <Privacy path="/terms-privacy" />
          <Service path="/terms-service" />
          <Return path="/terms-return" />
        </Layout>

        <NotFoundPage path="/404" />
        <NotFoundPage path="*" />
      </ScrollToTop>
    </ReachRouter>
  );
}
