import React from "react";
import Icon from "./Icon";

import CLDxMetap_card_front from "../images/CLDxMetap_card_front.png";

export default function MetapCard({
  form,
  image,
  setform,
  nameColor,
  textCenter,
}) {
  const name = form.name;

  return (
    <div className="lg:pl-16 pl-0 text-white">
      <div style={{ maxWidth: 450, maxHeight: 300 }} className="relative">
        <img
          src={CLDxMetap_card_front}
          className="m-auto rounded w-full h-full"
          alt="2"
          style={{ boxShadow: "0 0 10px #dfdfdf" }}
        />
        <div
          className={`absolute font-bold`}
          style={
            textCenter
              ? {
                  color: nameColor,
                  left: 0,
                  bottom: 0,
                  top: -10,
                  right: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 18,
                  wordBreak: "break-word",
                }
              : {
                  color: nameColor,
                  left: 24,
                  bottom: 18,
                  fontSize: 26,
                  wordBreak: "break-word",
                }
          }
        >
          {name}
        </div>
      </div>

      <div
        className="pt-4 flex items-center justify-center"
        style={{ maxWidth: 400, color: "black" }}
      >
        <div className="pr-1">
          <Icon type="info" width={20} />
        </div>
        Ảnh minh họa
      </div>
    </div>
  );
}
