import React from "react";
import DatMua_button from "../images/DatMua_button.png";

export default function InfoForm({
  setisDrawer,
  form,
  setform,
  onBuy,
  seterror,
  error,
  name,
}) {
  return (
    <>
      <form className="form shadow-md rounded text-white">
        <h2 className="font-bold text-center text-4xl mt-4">
          Đăng ký đặt hàng
        </h2>
        <div className="rounded my-4" role="group">
          <div className="font-bold text-xl mt-2">
            Tên trên thẻ: {form.name}
          </div>
          {form.type === 5 ? (
            <div className="font-bold text-xl mt-2">
              Tuỳ chọn: {form.nameTag}
            </div>
          ) : null}
          <div className="font-bold text-xl mt-2">Loại thẻ: {name}</div>
          <div className="font-bold text-xl mt-2">
            Vui lòng nhập các thông tin sau:
          </div>
        </div>

        <div className="pb-3">
          <label className="block text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <div className="flex">
            <input
              value={form.email}
              onChange={(e) => setform({ ...form, email: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="email"
              type="text"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="pb-3">
          <label className="block text-sm font-bold mb-2" htmlFor="Phone">
            Số điện thoại
          </label>
          <div className="flex">
            <input
              value={form.phone}
              onChange={(e) => setform({ ...form, phone: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="Phone"
              type="text"
              placeholder="Số điện thoại người nhận hàng"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-bold mb-2" htmlFor="address">
            Địa chỉ nhận hàng cụ thể, vui lòng ghi rõ tên tỉnh, thành phố
          </label>
          <div className="flex">
            <input
              value={form.address}
              onChange={(e) => setform({ ...form, address: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="address"
              type="text"
              placeholder="Địa chỉ nhận hàng cụ thể, vui lòng ghi rõ tên tỉnh, thành phố"
            />
          </div>
        </div>
      </form>
      <div className="pt-2 text-center text-red-600 text-sm italic">
        {error}
      </div>
      <div className="flex w-full justify-center pt-4">
        {/* <button
          onClick={() => {
            if (form.address && form.email) {
              onBuy(form);
              seterror(null);
            } else {
              seterror("Vui lòng nhập đủ thông tin");
            }
          }}
          className="md:w-48 w-full px-4 py-2 rounded-md focus:outline-none btn-primary font-bold"
          style={{ border: "2px solid green", maxWidth: 500 }}
        >
          ĐẶT MUA
        </button> */}
        <button
          onClick={() => {
            if (form.address && form.email) {
              onBuy(form);
              seterror(null);
            } else {
              seterror("Vui lòng nhập đủ thông tin");
            }
          }}
          className="focus:outline-none"
        >
          <img src={DatMua_button} className="md:h-12 h-10 w-auto" alt="2" />{" "}
        </button>
      </div>
    </>
  );
}
