import React, { useEffect, useState } from "react";
// import styled from "styled-components";
import ModalForm from "./ModalForm";
import LazyLoad from "react-lazyload";

import whitecardFront from "../../images/CLDxMetap_nameinsert.mp4";
import CLDxMetap_rotate from "../../images/CLDxMetap_rotate.mp4";
import CLDxMetap_Graphic from "../../images/CLDxMetap_Graphic.png";
import iconImage1 from "../../images/CLDxMetap_icon1.png";
import iconImage2 from "../../images/CLDxMetap_icon2.png";
import iconImage3 from "../../images/CLDxMetap_icon3.png";
import DatMua_button from "../../images/DatMua_button.png";

import CLDxMetap_card_front from "../../images/CLDxMetap_card_front.png";
import CLD_logo from "../../images/CLD_logo.png";
import logo_white from "../../images/logo_white.png";

// import imageNew4 from "../images/new-4.png";
import CLDxMetap_tap from "../../images/CLDxMetap_tap.mp4";
import CLDxMetap_scan from "../../images/CLDxMetap_scan.mp4";
import MetapCard from "../../components/MetapCard";

const defaultForm = {
  name: "",
  phone: "",
  email: "",
  address: "",
  isWhite: false,
  type: 6,
};

// const defaultContacUs = {
//   name: "",
//   phone: "",
//   email: "",
//   message: "",
// };

const cardTypes = [
  {
    name: "Thẻ CLDxMetap",
    id: 6,
    price: "199,000",
    ori: "299,000",
    value: CLDxMetap_card_front,
    color: "white",
    center: false,
  },
];

function App({ location }) {
  const [isDrawer, setisDrawer] = useState(false);
  const [form, setform] = useState({ ...defaultForm });
  // const [contacUs, setcontacUs] = useState({ ...defaultContacUs });
  const currentImage = cardTypes.find((f) => f.id === form.type).value;
  const nameColor = cardTypes.find((f) => f.id === form.type).color;
  const textCenter = cardTypes.find((f) => f.id === form.type).center;
  const price = cardTypes.find((f) => f.id === form.type).price;
  const ori = cardTypes.find((f) => f.id === form.type).ori;
  const name = cardTypes.find((f) => f.id === form.type).name;

  useEffect(() => {
    if (location?.hash === "#rgbcard") {
      setform({ ...form, type: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.hash]);
  return (
    <>
      <section id="section1" className="lg:py-24 py-16 bg-white text-black">
        <div className="container">
          <div className="md:text-5xl text-4xl font-bold">
            <h2 style={{ maxWidth: 600 }}>
              An toàn chia sẻ
              <div>mạng xã hội</div>
            </h2>
          </div>
          <div className="pt-12">
            <div className="flex flex-wrap">
              <div className="md:w-1/2 w-full">
                <div className="relative block mt-4">
                  <LazyLoad>
                    <img
                      src={CLDxMetap_Graphic}
                      className="mx-auto"
                      style={{ maxWidth: 400, width: "100%" }}
                      alt="2"
                    />
                  </LazyLoad>
                </div>
              </div>
              <div className="md:w-1/2 w-full">
                <div className="md:mx-4 mx-0 mt-4">
                  <div className="md:text-3xl text-xl pb-12 font-bold">
                    KHÔNG CẦN CÀI ĐẶT GÌ THÊM
                  </div>
                  <div className="text-xl">
                    <div className="pb-10">
                      Chạm thẻ Metap vào điện thoại để chia sẻ thông tin, giảm
                      thời gian trao đổi các mạng xã hội như Facebook,
                      Instagram, Zalo, Số điện thoại, Email và thông tin liên
                      lạc
                    </div>
                    <ul className="lg:list-disc list-none">
                      <li>Không thu phí hàng tháng</li>
                      <li>Thoải mái đổi thông tin không giới hạn</li>
                      <li>Không giới hạn số lần chạm thẻ</li>
                      <li>An toàn, không yêu cầu quyền truy cập và mật khẩu</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="step" className="lg:py-24 py-16 bg-black">
        <div className="container text-white">
          <div className="flex flex-wrap text-center">
            <div className="md:w-1/3 w-full">
              <div className="mb-2 mt-4">
                <embed
                  className="m-auto"
                  src={iconImage1}
                  style={{ width: 60 }}
                  alt="2"
                />
              </div>
              <div className="font-bold pb-4 text-2xl">
                Bước 1<br />
                Nhập tên
              </div>
              <div className="line pt-4" />
              <div className="text-lg md:block hidden">
                Đặt mua thẻ
                <br /> theo tên của bạn
              </div>
              <div className="text-lg md:hidden block">
                Đặt mua thẻ theo tên của bạn
              </div>
            </div>
            <div className="md:w-1/3 w-full">
              <div className="mb-2 mt-4">
                <embed
                  className="m-auto"
                  src={iconImage2}
                  style={{ width: 60 }}
                  alt="2"
                />
              </div>
              <div className="font-bold pb-4 text-2xl">
                Bước 2<br />
                Thêm thông tin
              </div>
              <div className="line pt-4" />
              <div className="text-lg md:block hidden">
                Tự thêm thông tin
                <br /> theo hướng dẫn kèm theo thẻ
              </div>
              <div className="text-lg md:hidden block">
                Tự thêm thông tin theo hướng dẫn kèm theo thẻ
              </div>
            </div>
            <div className="md:w-1/3 w-full">
              <div className="mb-2 mt-4">
                <embed
                  className="m-auto"
                  src={iconImage3}
                  style={{ width: 60 }}
                  alt="2"
                />
              </div>
              <div className="font-bold pb-4 text-2xl">
                Bước 3<br />
                Sử dụng
              </div>
              <div className="line pt-4" />
              <div className="text-lg md:block hidden">
                Chạm thẻ
                <br /> để chia sẻ thông tin
              </div>
              <div className="text-lg md:hidden block">
                Chạm thẻ để chia sẻ thông tin
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="section3" className="lg:pt-24 pt-12 pb-16">
        <div className="container">
          <div className="md:text-5xl text-4xl font-bold">
            <h2>Bước 1: Nhập Tên</h2>
          </div>
          <div className="pt-12">
            <div className="flex flex-wrap">
              <div className="md:w-1/2 w-full">
                <div style={{ maxWidth: 400 }} className="mt-4">
                  <div className="pb-10 text-xl md:text-3xl text-xl">
                    Điền tên trên thẻ, đặt mua và thanh toán
                  </div>
                </div>
                <div>
                  <a href="#section5">
                    <div>
                      <LazyLoad>
                        <img
                          src={DatMua_button}
                          className="md:h-12 h-10 w-auto"
                          alt="2"
                        />
                      </LazyLoad>
                    </div>
                  </a>
                </div>
              </div>
              <div className="md:w-1/2 w-full">
                <div className="mt-4">
                  <LazyLoad>
                    <video width="100%" autoPlay playsInline loop muted>
                      <source src={whitecardFront} type="video/mp4" />
                    </video>
                  </LazyLoad>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="section4" className="py-20 bg-black text-white">
        <div className="container">
          <div className="md:text-5xl text-4xl font-bold">
            <h2 className="" style={{ maxWidth: 600 }}>
              Bước 2: Thêm thông tin cá nhân vào thẻ
            </h2>
          </div>
          <div className="md:pt-16 pt-12">
            <div className="flex flex-wrap">
              <div className="md:w-1/2 w-full">
                <div className="relative block">
                  <div className="md:pr-6 pr-0">
                    <LazyLoad>
                      <video width="100%" autoPlay playsInline loop muted>
                        <source src={CLDxMetap_rotate} type="video/mp4" />
                      </video>
                    </LazyLoad>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 w-full">
                <div className="flex items-center h-full md:mt-0 mt-6 justify-end">
                  <div className="text-md md:pl-6 pl-0">
                    <div
                      className="md:text-3xl text-xl"
                      style={{ maxWidth: 400 }}
                    >
                      Khi nhận hàng, bạn nhận được thẻ và tờ hướng dẫn, bạn làm
                      theo tờ hướng dẫn để tự thêm thông tin cá nhân vào thẻ.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="section2" className="lg:pt-24 pt-16">
        <div className="container">
          <div className="md:text-5xl text-4xl font-bold">
            <h2>Bước 3: Sử dụng</h2>
          </div>
          <div className="md:text-3xl text-2xl font-bold">
            Hoạt động trên cả Android và IOS, không cần cài đặt gì thêm
          </div>
          <div className="pt-12">
            <div className="flex flex-wrap">
              <div className="md:w-1/2 w-full text-center">
                <div>
                  <LazyLoad>
                    <video width="100%" autoPlay playsInline loop muted>
                      <source src={CLDxMetap_scan} type="video/mp4" />
                    </video>
                  </LazyLoad>
                </div>
                <div
                  className="text-2xl font-bold mt-4"
                  style={{ color: "#42444A" }}
                >
                  QUÉT THẺ
                </div>
                <div className="">
                  Hoạt động trên hầu hết điện thoại Android & iOS <br />
                  (iPhone 5S trở lên)
                </div>
              </div>
              <div className="md:w-1/2 w-full text-center">
                <div>
                  <LazyLoad>
                    <video width="100%" autoPlay playsInline loop muted>
                      <source src={CLDxMetap_tap} type="video/mp4" />
                    </video>
                  </LazyLoad>
                </div>
                <div
                  className="text-2xl font-bold mt-4"
                  style={{ color: "#42444A" }}
                >
                  CHẠM THẺ
                </div>
                <div>
                  Hoạt động trên hầu hết điện thoại Android & iOS <br />
                  (iPhone XS trở lên)
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="rgbcard"></section>
      <section id="section5" className="lg:pt-24 py-16">
        <div className="container">
          <div className="font-bold mb-4">
            <h2 className="md:text-3xl text-2xl text-center">
              Phiên bản kết hợp đặc biệt
              <br />
              <div className="sm:flex block items-center mt-4 justify-center pt-2">
                <div className="mt-2 ">
                  <LazyLoad>
                    <img
                      src={CLD_logo}
                      className="m-auto md:h-12 h-10 w-auto"
                      alt="2"
                    />
                  </LazyLoad>
                </div>
                <div className="mt-2 ml-4">
                  <LazyLoad>
                    <img
                      src={logo_white}
                      className="m-auto md:h-10 h-8 w-auto"
                      alt="2"
                    />
                  </LazyLoad>
                </div>
              </div>
            </h2>
          </div>
          <div className="pt-12">
            <div className="flex flex-wrap">
              <div className="md:w-1/2 w-full">
                <MetapCard
                  form={form}
                  setform={setform}
                  image={currentImage}
                  nameColor={nameColor}
                  textCenter={textCenter}
                />
              </div>
              <div className="md:w-1/2 w-full">
                <div className="ml-4">
                  <div className="pb-2 flex md:flex-row flex-col">
                    <span className="pr-4" style={{ margin: "auto 0" }}>
                      Loại thẻ:
                    </span>
                    <span className="font-bold">{name}</span>
                  </div>
                  {/* <div className="py-2 flex md:flex-row flex-col">
                    <span className="pr-4" style={{ margin: "auto 0" }}>
                      Màu sắc:
                    </span>
                    <div className="flex md:mt-0 mt-2 items-center">
                      {cardTypes.map((item, idx) => {
                        return (
                          <div
                            key={idx}
                            className={`card-type mr-4 cursor-pointer relative `}
                            style={
                              item.id === form.type
                                ? { width: 60, height: 40 }
                                : {}
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              if (item.id !== form.type) {
                                setform({ ...form, type: item.id });
                              }
                            }}
                          >
                            <div>
                              <img
                                style={
                                  item.id === form.type
                                    ? { boxShadow: "0px 7px 10px #1a202c" }
                                    : { boxShadow: "0 0 10px #dfdfdf" }
                                }
                                src={
                                  cardTypes.find((f) => f.id === item.id).value
                                }
                                className={`absolute w-full h-full`}
                                alt="2"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                  <div
                    className="pb-1"
                    style={{
                      maxWidth: 400,
                    }}
                  >
                    <span className="font-bold">Tên của bạn</span>
                    <span
                      className="text-sm float-right text-gray"
                      style={{ color: "#ccc" }}
                    >
                      24 ký tự
                    </span>
                  </div>
                  <div>
                    <input
                      value={form?.name}
                      maxLength="24"
                      onChange={(e) =>
                        setform({ ...form, name: e.target.value })
                      }
                      className="rounded-md border appearance-none w-full py-2 px-4 focus:outline-none"
                      style={{
                        maxWidth: 400,
                      }}
                      placeholder="Nhập tên của bạn"
                    />
                  </div>
                  <div
                    className="flex font-bold text-xl mt-2 justify-between items-center"
                    style={{
                      maxWidth: 400,
                    }}
                  >
                    <div className="flex">
                      <p className="pr-4" style={{ color: "#42444A" }}>
                        {price}₫
                      </p>
                      <del className="text-md italic" style={{ color: "#ccc" }}>
                        {ori}₫
                      </del>
                    </div>
                    <div className="text-sm " style={{}}>
                      Freeship toàn quốc
                    </div>
                  </div>
                  <div className="pt-2">
                    <button
                      className="focus:outline-none"
                      onClick={() => setisDrawer(true)}
                    >
                      <LazyLoad>
                        <img
                          src={DatMua_button}
                          className="md:h-12 h-10 w-auto"
                          alt="2"
                        />
                      </LazyLoad>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ModalForm
        form={form}
        setform={setform}
        isDrawer={isDrawer}
        setisDrawer={setisDrawer}
        price={price}
        name={name}
      />
    </>
  );
}

export default App;
