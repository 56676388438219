import React from "react";
import Icon from "../../components/Icon";
import Fanpages from "../../components/Fanpages";
import { Link } from "@reach/router";

export default function Footer() {
  return (
    <section id="section7" className="lg:pt-10 py-10 bg-black text-white">
      <div className="container">
        <div className="">
          <div className="flex flex-wrap">
            <div className="md:w-1/3 w-full">
              <div className="mx-4  mt-4">
                <div className="wrap-logo">
                  <a href="/">
                    <div
                      className="font-bold text-5xl text-logo"
                      style={{
                        letterSpacing: -5,
                        lineHeight: 1,
                        color: "white",
                      }}
                    >
                      metap
                    </div>
                  </a>
                </div>
                <div>Theo dõi chúng tôi tại</div>
                <div className="flex">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/dunglailaptrinh"
                  >
                    <Icon
                      type="facebook-f"
                      className="rounded-md flex justify-center items-center p-1"
                      style={{
                        width: 42,
                        height: 40,
                        color: "#fff",
                        backgroundColor: "#1d4b99",
                      }}
                    />
                  </a>
                </div>
                <div className="mt-6 text-sm">
                  <div>dunglailaptrinh@gmail.com</div>
                  <div>©2020 METAP</div>
                </div>
              </div>
            </div>
            <div className="md:w-1/3 w-full">
              <div className="mx-4 text-sm" style={{ lineHeight: 1.7 }}>
                <div className="mb-4 font-bold text-xl mt-4">
                  Thông tin liên hệ
                </div>
                <div>Nhắn tin vào page facebook Dũng Lại Lập Trình</div>
                <div>hoặc page Thẻ Thông Minh Metap</div>

                <span>&nbsp;</span>
                <div>
                  Sô 34 Ngõ 9 đường Võ Chí Công, phường Nghĩa Đô, quận Cầu Giấy,
                  Hà Nội, Việt Nam
                </div>
                <div>Hotline: 0888421173 (Mrs. Hương)</div>
              </div>
            </div>
            <div className="md:w-1/3 w-full">
              <div
                className="text-sm lg:ml-24 mx-4 "
                style={{ lineHeight: 1.7 }}
              >
                <div className="mb-4 font-bold text-xl mt-4">metap</div>
                <div>Thay đổi thông tin trên thẻ tại:</div>
                <div>info.metap.vn</div>
                <span>&nbsp;</span>

                <div>
                  <div>
                    <Link className="text-sm" to="/terms-privacy">
                      Chính sách bảo mật
                    </Link>
                  </div>
                  <div>
                    <Link className="text-sm" to="/terms-service">
                      Điều khoản dịch vụ
                    </Link>
                  </div>
                  <div>
                    <Link className="text-sm" to="/terms-return">
                      Chính sách đổi trả
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <Fanpages />
        </div>
      </div>
    </section>
  );
}
