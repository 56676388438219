import React, { useEffect } from "react";
import Header from "../Dashboard/Header";
import DatMua_button from "../../images/DatMua_button.png";
import CLD_logo from "../../images/CLD_logo.png";
import CLDxMetap from "../../images/CLDxMetap.png";
import LOGO_BLACK from "../../images/LOGO_BLACK.png";
import MessengerCustomerChat from "react-messenger-customer-chat/lib/MessengerCustomerChat";
import Footer from "../Dashboard/Footer";

export default function Layout({ children, location }) {
  useEffect(() => {
    fetchTracking();
  }, []);

  const fetchTracking = async () => {
    try {
      await fetch(process.env.REACT_APP_CORE_API + "tracking/?ref=cld")
        .then((resp) => {})
        .then(function (data) {
          console.log(data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main-body">
      <Header />
      <main className="paddingHeader">
        <div
          id="main-slider"
          style={{
            // backgroundImage: `url(${backbroundImage})`,
            backgroundColor: "black",
          }}
          className="md:pt-16 md:pb-12 pt-0 pb-6"
        >
          <div className="container">
            <div className="flex flex-wrap">
              <div className="md:w-1/2 w-full">
                <div className="py-16 text-center text-white">
                  <div className="md:text-xl text-lg">Dự án kết hợp bởi</div>
                  <div className="sm:flex block items-center mt-4 justify-center">
                    <div className="mt-2 ">
                      <img
                        src={CLD_logo}
                        className="m-auto md:h-10 h-10 w-auto"
                        alt="2"
                      />
                    </div>
                    <div className="mt-2 ml-4">
                      <img
                        src={LOGO_BLACK}
                        className="m-auto md:h-8 h-8 w-auto"
                        alt="2"
                      />
                    </div>
                  </div>
                  <div
                    className="line-cld mx-auto my-8"
                    style={{ width: 200 }}
                  ></div>
                  <div
                    className="md:text-5xl text-3xl font-bold my-8"
                    style={{ lineHeight: 1.2 }}
                  >
                    <div>THẺ CÁ NHÂN</div>
                    <div>THÔNG MINH</div>
                  </div>
                  <a href="/#section5">
                    <div>
                      <img
                        src={DatMua_button}
                        className="m-auto md:h-12 h-10 w-auto"
                        alt="2"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="md:w-1/2 w-full flex justify-center items-center">
                <div>
                  <img
                    className="mt-4 m-auto h-auto px-8"
                    src={CLDxMetap}
                    style={{ width: "100%" }}
                    alt="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{children}</div>
      </main>
      <Footer />

      <MessengerCustomerChat pageId="100441868337707" appId="160407112496713" />
    </div>
  );
}
