import React, { useState } from "react";
import InfoForm from "../../components/InfoForm";
import Modal from "../../components/Modal";

const defaultForm = {
  name: "",
  phone: "",
  email: "",
  address: "",
  isWhite: false,
  type: 2,
};
export default function ModalForm({
  form,
  setform,
  isDrawer,
  setisDrawer,
  price,
  name,
}) {
  const [loading, setloading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(null);

  const onBuy = async (form) => {
    setloading(true);

    try {
      await fetch(process.env.REACT_APP_CORE_API + "buy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...form,
          ref: "cld",
        }),
      })
        .then((resp) => {
          console.log(resp);
          return resp.json();
        })
        .then(function (data) {
          if (data.error) {
            seterror(data.error);
          } else {
            setsuccess(data);
            // setform({ ...defaultForm });
            seterror(null);
          }
        });
    } catch (error) {
      seterror(error?.response);
    }

    setloading(false);
  };

  return (
    <Modal
      isModalOpen={!!isDrawer}
      style={{ maxWidth: 600 }}
      closeModal={() => {
        setsuccess(false);
        setisDrawer(false);
      }}
    >
      {loading && (
        <div className="loading-data">
          <div className="loader" />
        </div>
      )}
      {success && !error ? (
        <div className="text-center text-white">
          <h2 className="font-bold text-center md:text-4xl text-2xl pb-4">
            Vui lòng thanh toán để
            <br />
            hoàn tất đơn hàng:
          </h2>

          <div>
            <div>
              <span className="font-bold"> Số tài khoản: 21510001910046</span>
            </div>
            <div>
              <span className="font-bold">
                {" "}
                Tên chủ tài khoản: LAI PHU DUC{" "}
              </span>
            </div>
            <div>
              <span className="font-bold">
                {" "}
                Ngân hàng BIDV chi nhánh Hoàng Quốc Việt{" "}
              </span>
            </div>
          </div>
          <div className="md:text-4xl text-2xl my-2">{price}₫</div>
          <div className="">
            Với nội dung là:{" "}
            <span className="font-bold">
              {success?.id} {success?.phone}
            </span>
          </div>
          <div className="pt-4">
            Thông tin chi tiết đơn hàng đã gửi vào địa chỉ email của bạn
          </div>
          <div className="">
            Sau khi thanh toán, tin nhắn xác nhận sẽ được gửi trong 12h, thẻ sẽ
            đến tay bạn trong 1-2 tuần.
          </div>
          <div className="pt-4 font-bold">
            Cảm ơn bạn đã sử dụng dịch vụ của chúng tôi
          </div>
        </div>
      ) : (
        <InfoForm
          setisDrawer={setisDrawer}
          form={form}
          setform={setform}
          onBuy={onBuy}
          seterror={seterror}
          error={error}
          name={name}
        />
      )}
    </Modal>
  );
}
