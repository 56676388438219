import React, { useEffect, useState } from "react";
import cldLogo from "../../images/logo-rm.png";

export default function Header() {
  const [sticky, setsticky] = useState("sticky-header");
  useEffect(() => {
    if (window.scrollTop === 0) {
      setsticky("");
    } else {
      setsticky("sticky-header");
    }
  }, []);

  return (
    <div
      id="site-header"
      className={"flex items-center main-header " + sticky}
      style={{ minHeight: 68 }}
    >
      <div className="container">
        <div className="flex items-center py-4 px-4">
          <a target="_blank" href="https://chongluadao.vn/">
            <img
              src={cldLogo}
              className="m-auto md:h-20 sm:h-16 h-12 w-auto"
              alt="2"
            />
          </a>
          <div className="wrap-logo ml-8">
            <a target="_blank" href="https://metap.vn/?ref=cld">
              <div
                className="font-bold md:text-5xl text-4xl text-logo"
                style={{ letterSpacing: -5, lineHeight: 1 }}
              >
                metap
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
