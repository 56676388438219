import React from "react";

export default function Fanpages() {
  return (
    <div className="flex flex-wrap m-auto" style={{ maxWidth: 800 }}>
      <div className="md:w-1/2 w-full flex justify-center mt-6">
        <div
          class="fb-page"
          data-href="https://www.facebook.com/chongluadao.vn"
          data-tabs=""
          data-width=""
          data-height=""
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/chongluadao.vn"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/chongluadao.vn">Chongluadao.vn</a>
          </blockquote>
        </div>
      </div>
      <div className="md:w-1/2 w-full flex justify-center mt-6">
        <div
          class="fb-page"
          data-href="https://www.facebook.com/metapvn"
          data-tabs=""
          data-width=""
          data-height=""
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/metapvn"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/metapvn">Thẻ Thông Minh Metap</a>
          </blockquote>
        </div>
      </div>
    </div>
  );
}
